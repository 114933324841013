

.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .body {
    position: relative; /* Ensure content is above the overlay */
    z-index: 2; /* Ensure content is above the overlay */
    display: flex;
    flex-direction: row;

    .left {
      display: flex;
      width: 50vw;
      align-items: center;
      justify-content: center;
      .announcement {
        font-size: 40px;
        padding: 40px;
        max-width: 1120px;
        color: white;
      }

      .email {
        color: white;
        font-size: 26px;
        padding: 20px 40px 0px 40px;
      }

      .address {
        padding: 0px 40px;
        font-family: "Benzin Regular";
        span {
          color: white;
          margin-right: 20px; /* Add space between spans */
        }
      }
    }
    .right {
      width: 50vw;
      img {
        padding: 100px;
        width: 100%;
      }
    }
  }
}
