/* SubscriptionPrompt.css */
.subscription-prompt {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;

  z-index: 1000;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.subscription-content {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start;
  padding: 20px;
  min-width: 390px;

  @media (max-width: 400px) {
    min-width: 300px;
  }
}

.subscription-content p {
  margin-bottom: 10px; /* Space between text and input */
  font-size: 16px;
  font-family: "Noah Regular";
  margin-top: 0px;
  margin-bottom: 20px;
  line-height: 16.8px;
  animation: fadeIn 1s ease-out forwards;
  @media (max-width: 400px) {
    font-size: 14px;
  }
}

.email-input {
  position: relative;
  display: flex;
  width: 100%;
}

.email-input input[type="email"] {
  font-family: "Noah Regular";
  font-size: 12px;
  padding: 10px;
  padding-left: 15px;
  border: none;
  border-radius: 20px;
  flex-grow: 1;
  border-right: none; /* Remove right border where button will be */
  background-color: rgb(228, 228, 228);
  height: 26px;
}

.email-btn {
  padding: 10px 15px;
  background-color: rgb(228, 228, 228);
  font-size: 18px;
  border: none;

  border-radius: 0 20px 20px 0; /* Rounded right corners */
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(170, 170, 170);
}

.cookie-consent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  animation: fadeIn 0.5s ease-out forwards;
  transition: opacity 0.3s ease-in-out;
  gap: 10px; /* Space between cookie text and buttons */
  padding: 15px 20px;

  @media (max-width: 400px) {
    justify-content: flex-end;
    padding: 10px 10px;
    gap: 5px;
  }
}

.cookie-consent p {
  margin: 0;
  font-size: 14px;
  font-family: "Noah Regular";
  margin-right: 15px;
  @media (max-width: 400px) {
    font-size: 11px;
  }
}

.cookie-consent button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  
}

.cookie-consent button.accept {
  color: black;
  text-decoration: none; /* Makes it clear it's clickable */
  font-size: 14px;
  font-family: "Noah Regular";
  padding-right: 5px;
  transition: opacity 0.3s ease-in-out;
  @media (max-width: 400px) {
    font-size: 10px;
  }
}

.cookie-consent button.accept:hover {
  opacity: 0.5; /* Decrease opacity on hover */
}

.cookie-consent button.decline {
  opacity: 0.4;
  color: #000;
  text-decoration: none; /* No underline for decline */
  font-size: 14px;
  font-family: "Noah Regular";
  @media (max-width: 400px) {
    font-size: 10px;
  }
}

.cookie-consent button.decline:hover {
  opacity: 0.8; /* Darken text on hover */
}

.cookie-consent .active-consent {
  position: relative;
}

.cookie-consent .active-consent::before {
  content: "•";
  color: black;
  position: absolute;
  left: -20px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
}

.line-spacer {
  height: 1px;
  background-color: black;
}

.fade-out-down {
  animation: fadeOutDown 0.5s ease forwards;
}

.fade-out-right {
  animation: fadeOutRight 0.5s ease forwards;
}

.fade-out-up {
  animation: fadeOutUp 0.5s ease forwards;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 0;
    transform: translateX(0);
  }
  to {
    opacity: 1;
    transform: translateX(40px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

