$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;

:root {
  --title-size: clamp(5rem, 7.5vw, 10rem);
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1; /* Ensures video stays behind the content */

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.container {
  display: flex;
  height: 100svh;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-position: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .body {
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .announcement {
      font-size: 36px;
      padding: 40px;
      padding-top: 10vh;
      max-width: 1120px;
      color: white;
      box-sizing: border-box;
      // align-self: center; // Center the announcement horizontally
      margin: auto 0; // Center the announcement vertically in the available space

      @media (max-width: $breakpoint-tablet) {
        font-size: 30px;
        padding: 32px;
        height: unset;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 20px;
        padding: 32px;
        padding-top: 20vh;
        height: unset;
      }
    }

    .email {
      color: white;
      font-size: 24px;
      padding: 0 40px; // Remove top and bottom padding to control space
      // margin-bottom: 8px; // Create a fixed margin of 32px above the address
      box-sizing: border-box;
      word-break: break-word;

      @media (max-width: $breakpoint-tablet) {
        font-size: 20px;
        padding: 0 32px;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 14px;
        padding: 0 32px;
      }
    }

    .address {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 40px 16px 40px;
      font-family: "Benzin Regular";
      font-size: 16px;
      box-sizing: border-box;
      color: white;

      @media (max-width: $breakpoint-tablet) {
        font-size: 13px;
        padding: 0px 32px;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 12px;
        padding: 0px 32px;
        padding-bottom: 16px;
        flex-direction: column;
      }

      span {
        color: white;
        margin-right: 20px;
      }

      .phoneNumber {
        margin-left: auto;
        color: white;
        margin-right: 0px;
        @media (max-width: $breakpoint-mobile) {
          margin-left: 0;
          margin-top: 10px;
          align-self: flex-end;
          display: none;
        }
      }
      .mobilePortugal {
        @media (max-width: $breakpoint-mobile) {
          display: flex;
          justify-content: space-between;
          margin-right: 0px;
        }

        .phoneNumberMobile {
          display: none;


          @media (max-width: $breakpoint-mobile) {
            display: inline;
            margin-left: auto;
            margin-right: 0px;

        
          }
        }
      }
    }
  }

  .logo {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 3;
    @media (max-width: $breakpoint-mobile) {
      top: 32px;
      left: 32px;
    }

    img {
      max-width: 180px;

      @media (max-width: $breakpoint-mobile) {
        max-width: 130px;
      }
    }
  }
}
