/* Noah Regular */
@font-face {
  font-family: "Noah Regular";
  src: url("./assets/fonts/Noah-Regular.ttf") format("truetype");
}

/* Noah Bold */
@font-face {
  font-family: "Noah Bold";
  src: url("./assets/fonts/Noah-Bold.ttf") format("truetype");
}


@font-face {
  font-family: "Benzin Bold";
  src: url("./assets/fonts/Benzin-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Benzin Regular";
  src: url("./assets/fonts/Benzin-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Benzin Extra Bold";
  src: url("./assets/fonts/Benzin-ExtraBold.ttf") format("truetype");
}


/* @font-face {
  font-family: "GoliVF";
  src: url("./assets/fonts/GoliVF.ttf") format("truetype");
  font-weight: 100 900; 
  font-style: normal;
  font-optical-sizing: auto;
} */



/* 
Font options original

"Nexa",
"NexaRustHandmade-Extended",
"NexaRustScriptL-0",
"NexaRustSans-Black",
"NexaRustSlab-BlackShadow01",
"NexaRustExtras-Free" 
"LexendPeta",
"LexendZetta",
"LexendTera-Regular",
"LexendExa-Regular",
"LexendDeca-Regular",
"LexendGiga-Regular",

*/

body {
  font-family: "Benzin Bold";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
h1,
h2,
h3,
p,
ul,
li {
  /* margin: 0; */
  padding: 0;
  border: 0;
}

/* Use a consistent box-sizing model */
* {
  box-sizing: border-box;
}

/* @import 'normalize.css'; */
